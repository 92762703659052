import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { Input, Container } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import LoadingPlaceholder from './LoadingPlaceholder';

const ArticleForm = styled.form`
  padding: 2rem 0;
`;

const ArticleFrame = styled.iframe`
  width: 100%;
  height: 80vh;
  display: ${props => (props.visible ? 'inherit' : 'none')};
  border: 2px solid #dddddd;
`;

const NewArticle = () => {
  const [newArticleURL, setArticleURL] = useState('');
  const [loading, setLoading] = useState(false);
  const [displayIframe, setDisplayIframe] = useState(false);
  const iframeRef = useRef(null);

  const handleSubmit = async event => {
    event.preventDefault();
    setLoading(true);
    const response = await fetch('/api/new', {
      method: 'POST',
      body: JSON.stringify({
        url: newArticleURL,
      }),
    });
    setLoading(false);
    const { url, origin, html, date } = await response.json();
    iframeRef.current.contentWindow.document.write(html);
    setDisplayIframe(true);
  };

  return (
    <Container>
      <ArticleForm onSubmit={handleSubmit}>
        <Input
          fluid
          icon="newspaper"
          iconPosition="left"
          disabled={loading}
          loading={loading}
          action={{ icon: 'search' }}
          onChange={({ currentTarget: { value } }) => {
            setArticleURL(value);
          }}
          value={newArticleURL}
          placeholder="Article URL"
        />
      </ArticleForm>
      {loading && <LoadingPlaceholder />}
      <ArticleFrame title="article" ref={iframeRef} visible={displayIframe} />
    </Container>
  );
};

export default NewArticle;
