import React, { useState, useEffect } from 'react';
import { Container, List } from 'semantic-ui-react';

const PastArticles = () => {
  const [pastArticles, setPastArticles] = useState([]);
  useEffect(() => {
    (async () => {
      const response = await fetch('/api/past');
      const { data } = await response.json();
      setPastArticles(data);
    })();
  }, []);

  return (
    <Container>
      <List>
        {pastArticles.map(({ url }) => (
          <List.Item>
            <List.Icon name="linkify" />
            <List.Content>
              <a href={url}>{url}</a>
            </List.Content>
          </List.Item>
        ))}
      </List>
    </Container>
  );
};

export default PastArticles;
