import React, { useState } from 'react';
import NewArticle from '../components/NewArticle';
import PastArticles from '../components/PastArticles';

const IndexPage = () => {
  const [currentPage, setCurrentPage] = useState('new');
  switch (currentPage) {
    case 'new':
      return (
        <div>
          <NewArticle />
          <PastArticles />
        </div>
      );
    default:
      return (
        <div>
          <NewArticle />
          <PastArticles />
        </div>
      );
  }
};

export default IndexPage;
