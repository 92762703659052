import React from 'react';
import { Placeholder } from 'semantic-ui-react';

export default () => (
  <Placeholder fluid>
    <Placeholder.Header>
      <Placeholder.Line length="long" />
      <Placeholder.Line length="medium" />
    </Placeholder.Header>
    <Placeholder.Paragraph>
      <Placeholder.Line />
      <Placeholder.Line />
      <Placeholder.Line />
      <Placeholder.Line />
    </Placeholder.Paragraph>
    <Placeholder.Paragraph>
      <Placeholder.Line length="very short" />
      <Placeholder.Line length="medium" />
      <Placeholder.Line length="very long" />
      <Placeholder.Line length="long" />
      <Placeholder.Line length="full" />
    </Placeholder.Paragraph>
    <Placeholder.Paragraph>
      <Placeholder.Line length="very short" />
      <Placeholder.Line length="medium" />
      <Placeholder.Line length="medium" />
      <Placeholder.Line length="short" />
    </Placeholder.Paragraph>
  </Placeholder>
);
